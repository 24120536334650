/*
 * @Author: yangmiaomiao
 * @Date: 2021-11-08 10:31:54
 * @LastEditors: mawenli
 * @LastEditTime: 2021-12-30 09:58:18
 * @Description:配置列表页store
 */
import { getPatientList } from 'service/patientApi'
import { getMedicationList } from 'service/medicationApi'
const listStoreConfig = [
    {
        name: 'patientList',
        request_api: getPatientList,
        tip: '患者列表',
    },
    {
        name: 'medicationList',
        request_api: getMedicationList,
        tip: '用药方案',
    },
]

export { listStoreConfig }
