/*
 * @Author: duchengdong
 * @Date: 2021-10-12 18:20:19
 * @LastEditors: yangmiaomiao
 * @LastEditTime: 2021-12-30 11:26:16
 * @Description:
 */
export function uuid(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8
        return v.toString(16)
    })
}
// 取url参数
export function getSearch(): any {
    let search = window.location.search.slice(1).split('&')
    let params = {}
    for (let i = 0; i < search.length; i++) {
        let param = search[i].split('=')
        params[param[0]] = decodeURIComponent(param[1])
    }
    return params
}
//平铺
export const flatDeepth = (arr: any, link = 'children') => {
    return arr.reduce((pre, cur) => pre.concat(cur[link] != null ? [{ ...cur }].concat(flatDeepth(cur[link])) : { ...cur }), [])
}

//防抖：在一定时间间隔内，多次调用一个方法，只会执行一次.
export function debounce(fn: any, t: number = 800) {
    let timeId: NodeJS.Timeout | null = null
    const delay = t
    return function (this: any, ...args: any) {
        if (timeId) clearTimeout(timeId)
        timeId = setTimeout(() => {
            timeId = null
            fn.apply(this, args)
        }, delay)
    }
}

//节流：多次调用方法，按照一定的时间间隔执行
export function throttle(fn: any, wait: number = 500) {
    let canRun = true // 通过闭包保存一个标记
    return function (this: any, ...args: any) {
        if (!canRun) return // 在函数开头判断标记是否为true，不为true则return
        canRun = false // 立即设置为false
        setTimeout(() => {
            // 将外部传入的函数的执行放在setTimeout中
            fn.apply(this, args)
            // 最后在setTimeout执行完毕后再把标记设置为true(关键)表示可以执行下一次循环了。当定时器没有执行的时候标记永远是false，在开头被return掉
            canRun = true
        }, wait)
    }
}
//返回有长度的空数组
export const numToArray = (start = 0, end = 10) => {
    let arr: any = []
    for (var i = start; i <= end; i++) {
        arr.push(i)
    }
    return arr
}

//根据年-月-日 获取年龄
export function getAge(strBirthday: string) {
    let returnAge
    let returnAgeTxt
    let strBirthdayArr = strBirthday.split('-')
    let birthYear = strBirthdayArr[0]
    let birthMonth = strBirthdayArr[1]
    let birthDay = strBirthdayArr[2]

    let d = new Date()
    let nowYear = d.getFullYear()
    let nowMonth = d.getMonth() + 1
    let nowDay = d.getDate()

    if (Number(nowYear) === Number(birthYear)) {
        returnAge = 0 //同年 则为0岁
        //返回月
        returnAgeTxt = [12 - Number(birthMonth), '月']
    } else {
        let ageDiff = nowYear - Number(birthYear) //年之差
        if (ageDiff > 0) {
            if (Number(nowMonth) === Number(birthMonth)) {
                let dayDiff = nowDay - Number(birthDay) //日之差
                if (dayDiff < 0) {
                    returnAge = ageDiff - 1
                } else {
                    returnAge = ageDiff
                }
            } else {
                let monthDiff = nowMonth - Number(birthMonth) //月之差
                if (monthDiff < 0) {
                    returnAge = ageDiff - 1
                } else {
                    returnAge = ageDiff
                }
            }
            returnAgeTxt = [returnAge, '岁']
        } else {
            returnAge = -1 //返回-1 表示出生日期输入错误 晚于今天
            returnAgeTxt = [0, '岁']
        }
    }
    return { returnAge, returnAgeTxt } //返回周岁年龄
}
