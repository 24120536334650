/*
 * @Author: yangmiaomiao
 * @Date: 2021-11-10 11:23:08
 * @LastEditors: yangmiaomiao
 * @LastEditTime: 2021-12-30 11:44:35
 * @Description:需要做处理转换的一些方法
 */
import { OptionType } from 'types/config.type'
import { OptionTitleProps, BaseInfoItemType } from 'types/pagesfunc.type'
import { MenuItem } from 'types/store.type'
import RouteConfig from 'routes/router.config'
import { IRouteViewProps } from 'routes/RouteView'
import { flatDeepth } from './utils'

//根据value获取options中的title值
export const getOptionsTitleFn = (props: OptionTitleProps) => {
    const { options, value, key = 'value', name = 'title', returnValue = '' } = props
    const find = options.find((v: OptionType) => v[key] == value)
    if (find) {
        return find[name]
    }
    return returnValue
}

//详情：如用户管理详情、患者管理详情中基本信息展示
export function dealBaseInfoFn(obj: {}, info: {}) {
    let data: BaseInfoItemType[] = []
    Object.keys(obj).forEach((v) => {
        if (obj[v]) {
            data.push({
                name: obj[v],
                value: info[v],
                key: v,
            })
        }
    })
    return data
}

//获取菜单结构Route处理
export const NavRoutesFunc = (menuList: MenuItem[]) => {
    //菜单扁平化获取所有的path
    const allPath = flatDeepth(menuList).map((v: MenuItem) => v.uri)

    //父级菜单（第一级菜单）查找，全等，兼容后台返回'/'结构
    function firstFindCongruent(path: string = '') {
        return allPath.findIndex((v: string) => path === v)
    }

    //子级菜单allPath中查找path是否包含在RouteConfig中的path
    function childFindIncludes(path: string = '') {
        return allPath.findIndex((v: string) => path.includes(v))
    }

    const routerList = RouteConfig[1].children?.filter((item: IRouteViewProps) => {
        if (firstFindCongruent(item.path) !== -1) {
            const children = item.children?.filter((child: IRouteViewProps) => {
                if (childFindIncludes(child.path) !== -1) {
                    return true
                }
                return false
            })
            item.children = children
            return true
        }
        return false
    })

    const routes = [
        { ...RouteConfig[0] },
        {
            ...RouteConfig[1],
            children: routerList,
        },
    ]
    return routes
}
