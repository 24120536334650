/*
 * @Author: duchengdong
 * @Date: 2021-10-12 14:21:16
 * @LastEditors: yangmiaomiao
 * @LastEditTime: 2022-03-09 10:08:53
 * @Description:
 */
import { useState, useEffect, Suspense } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import useStoreHooks from 'hooks/useStoreHooks'
import LoadingHtmp from 'layout/LoadingHtmp'
import { initRouteConfig } from './routes/router.config'
import RouteView, { IRouteViewProps } from './routes/RouteView'
import { naviData } from 'layout/datas'
import { NavRoutesFunc } from 'utils/pagesFunc'

function App() {
    const [routeArr, setRouteArr] = useState<IRouteViewProps[]>(initRouteConfig)
    const [, actions, dispatch] = useStoreHooks('globle')
    const { saveNavMenuList } = actions

    const notAppEffect = [process.env.REACT_APP_loginPath, process.env.REACT_APP_errorPath]

    useEffect(() => {
        if (notEffect()) {
            // console.log('app=正常渲染')
            //1.在store中存储菜单信息
            dispatch(saveNavMenuList(naviData?.data))
            //2.Route处理
            const routes = NavRoutesFunc(naviData?.data)
            // console.log(routes, 'routes')

            setRouteArr(routes)
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function notEffect() {
        let flag = true
        if (notAppEffect.findIndex((v) => v === window.location.pathname) !== -1) {
            console.log('app=登录页面不走该渲染')
            //登录页面不走该渲染
            flag = false
            return 1
        }
        return flag
    }

    return (
        <Router basename="/">
            <Suspense fallback={<LoadingHtmp />}>
                <RouteView children={routeArr} />
            </Suspense>
        </Router>
    )
}

export default App
