/*
 * @Author: zhicheng.yao
 * @Date: 2021-10-20 10:40:14
 * @Last Modified by: zhicheng.yao
 * @Last Modified time: 2021-11-06 13:44:33
 */
export const naviData = {
    code: 100,
    msg: '成功',
    data: [
        {
            id: 1069,
            parentCode: 'operation',
            parentId: 0,
            menuCode: 'operation',
            name: '运营',
            uri: '/operation',
            icon: '#',
            children: [
                {
                    id: 1070,
                    parentCode: 'operation',
                    parentId: 1069,
                    menuCode: 'PatientService',
                    name: '患者服务',
                    uri: '/operation/patient',
                    icon: 'icon-huanzhefuwu',
                    children: [
                        {
                            id: 1071,
                            parentCode: 'PatientService',
                            parentId: 1070,
                            menuCode: 'PatientManage',
                            name: '患者管理',
                            uri: '/operation/patient/patientmanage',
                            icon: '#',
                            children: [],
                        },
                        {
                            id: 1072,
                            parentCode: 'PatientService',
                            parentId: 1070,
                            menuCode: 'PatientManage',
                            name: '用药方案',
                            uri: '/operation/patient/medicationregimen',
                            icon: '#',
                            children: [],
                        },
                    ],
                },
            ],
        },
    ],
    requestId: null,
    pageInfo: null,
}
