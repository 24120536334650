/*
 * @Author: duchengdong
 * @Date: 2021-10-27 10:28:45
 * @LastEditors: yangmiaomiao
 * @LastEditTime: 2022-03-02 11:44:10
 * @Description: 路由配置文件
 */
import AuthLayout, { BlankLayout } from 'layout/AuthLayout'
import { lazy } from 'react'

const RouteConfig = [
    {
        path: '/login',
        component: lazy(() => import('pages/Login')),
    },
    {
        path: '/',
        component: AuthLayout,
        children: [
            { path: '/', redirect: '/' },
            {
                name: '运营',
                path: '/operation',
                component: BlankLayout,
                children: [
                    {
                        name: '患者详情',
                        path: `/operation/patient/patientmanage/:type/:id`,
                        component: lazy(() => import('pages/PatientService/PatientManage/PatientDetail')),
                    },
                    {
                        name: '患者管理',
                        path: '/operation/patient/patientmanage',
                        component: lazy(() => import('pages/PatientService/PatientManage')),
                    },
                    {
                        name: '创建/编辑用药方案',
                        path: `/operation/patient/medicationregimen/:type/:id?`,
                        component: lazy(() => import('pages/PatientService/MedicationRegimen/MRAddOrEdit')),
                    },
                    {
                        name: '用药方案',
                        path: '/operation/patient/medicationregimen',
                        component: lazy(() => import('pages/PatientService/MedicationRegimen')),
                    },
                ],
            },
        ],
    },
]

export const initRouteConfig = [
    {
        path: '/login',
        component: lazy(() => import('pages/Login')),
    },
]

export default RouteConfig
