/*
 * @Author: duchengdong
 * @Date: 2021-10-12 14:21:16
 * @LastEditors: yangmiaomiao
 * @LastEditTime: 2022-01-06 18:41:33
 * @Description:
 */
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
// import reportWebVitals from './reportWebVitals'
import { ConfigProvider } from 'antd'
import zhCN from 'antd/es/locale/zh_CN'
import moment from 'moment'
import 'moment/locale/zh-cn'
import { store } from './store'
import App from './App'
import 'antd/dist/antd.less'
import './style/index.scss'
moment.locale('zh-cn')

ReactDOM.render(
    <ConfigProvider locale={zhCN}>
        <Provider store={store}>
            <App />
        </Provider>
    </ConfigProvider>,
    document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log)
