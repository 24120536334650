/*
 * @Author: yangmiaomiao
 * @Date: 2021-12-24 13:46:06
 * @LastEditors: yangmiaomiao
 * @LastEditTime: 2021-12-30 16:07:02
 * @Description:用药方案
 */

import http from 'utils/request'
import { PageInfo, ResObj } from 'types/config.type'

//用药方案列表
export const getMedicationList = (pageInfo: PageInfo, data: ResObj) => {
    return http({
        url: '/backend/medicineplan/info_list',
        method: 'post',
        data: { ...pageInfo, ...data },
    })
}

//用药打卡记录
export const getMedicationRecord = (id: string, pageInfo: PageInfo) => {
    return http({
        url: '/backend/medicineplan/medSighInfo',
        method: 'post',
        data: { ...pageInfo, medPlanId: id },
    })
}

//获取详情
export const getMedicationDetails = (id: string) => {
    return http({
        url: `/backend/medicineplan/info/${id}`,
        method: 'get',
    })
}

//创建+编辑
export const saveMedication = (data: ResObj) => {
    return http({
        url: `/backend/medicineplan/${data.id ? 'edit' : 'save'}`,
        method: 'post',
        data,
    })
}

//方案状态开关
export const changeMedSwitch = (data: ResObj) => {
    const { id, onOff } = data
    return http({
        url: `/backend/medicineplan/on_off?id=${id}&onOff=${onOff}`,
        method: 'get',
    })
}
