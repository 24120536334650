/*
 * @Author: duchengdong
 * @Date: 2021-10-18 11:50:27
 * @LastEditors: mawenli
 * @LastEditTime: 2021-12-23 18:02:41
 * @Description: 页面基本布局相关
 */
import { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Layout } from 'antd'
import ProjectTabs from './ProjectTabs'
import RouteView, { IRouteViewProps } from '../routes/RouteView'
import { Logout } from 'pages/Login/logout'
import useStoreHooks from 'hooks/useStoreHooks'
import { MenuItem } from 'types/store.type'
import { ResObj, RouteConfig } from 'types/config.type'
import './index.scss'

type ILayoutProps = RouteConfig & IRouteViewProps
type PropsType = ResObj

const { Header, Content, Sider } = Layout
const AuthLayout: React.FC<ILayoutProps> = (props) => {
    const { location } = props
    const [state] = useStoreHooks('globle')
    const { userInfo, navMenuList } = state
    const [currentPaths, setcurrentPaths] = useState([])
    const [secondList, setSecondList] = useState([])

    useEffect(() => {
        let url = location.pathname
        let [ids, secondList] = pathNameToIdFn(navMenuList, url)
        setcurrentPaths(ids)
        setSecondList(secondList)
        return () => {}
    }, [location.pathname, navMenuList])

    return (
        <Layout className="layout-container">
            <AuthHeader currentPaths={currentPaths} userInfo={userInfo} navMenuList={navMenuList} location={location} />
            <Layout>
                <Sider width={180}>
                    <div className="layout-sidebox">
                        <AuthSideNav secondList={secondList} location={location} currentPaths={currentPaths} />
                        <ProjectTabs />
                    </div>
                </Sider>

                <Content className="layout-content">
                    <RouteView {...props} />
                </Content>
            </Layout>
        </Layout>
    )
}
export default AuthLayout

//router引入模块路由时的component组件
export const BlankLayout: React.FC<ILayoutProps> = (props) => {
    return <RouteView {...props} />
}

//第一级菜单点击事件
export const handleHeaderLink = (item: any, link = 'children') => {
    let uri = item[link]?.length > 0 ? (item[link][0][link]?.length > 0 ? item[link][0][link][0].uri : item[link][0].uri) : item.uri
    return uri
}
const AuthHeader: FC<PropsType> = (props) => {
    const { navMenuList, location } = props
    return (
        <Header className="layout-header">
            <div className="left c-ff weight-500">
                {/*<img className="logo" src={`${process.env.PUBLIC_URL}/images/logo-nav.png`} alt="" />*/}
                愈淋同行管理后台
            </div>
            <div className="center">
                {
                    //判断第一级菜单指向，默认为第一个二级菜单下第一个三级菜单的路由，如果第一个二级菜单下没有三级菜单，则是二级菜单的路由
                    navMenuList.map((item: MenuItem) => {
                        return (
                            <Link key={item.uri} className={`menu ${location.pathname.includes(item.uri) ? 'active' : ''}`} to={handleHeaderLink(item)}>
                                {item.name}
                            </Link>
                        )
                    })
                }
            </div>
            <div className="right">
                {/*<img className="avatar" src={`${userInfo?.sysUser?.avatar || ''}`} alt="" />*/}
                {/*<span>{userInfo?.sysUser?.nickName || ''}</span>*/}
                <span className="dropout" onClick={Logout}>
                    退出
                </span>
            </div>
        </Header>
    )
}

//侧边栏 二级+三级菜单
const AuthSideNav: FC<PropsType> = (props) => {
    const { secondList, location } = props
    return (
        <div className="layout-sidenav">
            {secondList?.map((item: MenuItem) => {
                return item.children && item.children.length > 0 ? (
                    <ThirdMenu key={item.uri} item={item} location={location} />
                ) : (
                    <ThirdMenuOnly key={item.uri} location={location} item={item} />
                )
            })}
        </div>
    )
}

const ThirdMenu: FC<PropsType> = (props) => {
    const { item, location } = props
    const [isExpand, setIsExpand] = useState(location.pathname.includes(item.uri) ? true : false)
    return (
        <>
            <div
                key={item.uri}
                className="menu"
                onClick={() => {
                    setIsExpand(!isExpand)
                }}
            >
                <div className="menu-left">
                    <div className="icon-box">
                        {<span className={isExpand ? 'icon-zhankai iconfont icon-open' : 'icon-shouqi iconfont icon-retract'}></span>}
                    </div>
                    {item.icon && <em className={`nav-icon iconfont ${item.icon}`}></em>}
                    <span> {item.name}</span>
                </div>
            </div>
            <div
                className="third-menu"
                style={{
                    height: isExpand ? item?.children?.length * 40 + 'px' : 0,
                }}
            >
                {item.children.map((thirdMenu: MenuItem) => (
                    <Link key={thirdMenu.uri} className={location.pathname.includes(thirdMenu.uri) ? 'menu active' : 'menu'} to={thirdMenu.uri}>
                        <span> {thirdMenu.name}</span>
                    </Link>
                ))}
            </div>
        </>
    )
}
const ThirdMenuOnly: FC<PropsType> = (props) => {
    const { item, location } = props
    return (
        <Link key={item.uri} className={location.pathname.includes(item.uri) ? 'menu active' : 'menu'} to={item.uri}>
            <div className="menu-left">
                <div className="icon-box"></div>
                {item.icon && <em className={`nav-icon iconfont ${item.icon}`}></em>}
                <span> {item.name}</span>
            </div>
        </Link>
    )
}

//获取当前
function pathNameToIdFn(naviMetaData: any, pathname: string) {
    let menuCode = []
    let secondList = []
    //当前菜单列表
    let navis = naviMetaData.filter((v: ResObj) => pathname.includes(v.uri))
    let firstNavi = navis[0]
    if (firstNavi) {
        menuCode.push(firstNavi.menuCode as never)
        firstNavi?.children?.forEach((v: ResObj) => {
            menuCode.push(v.menuCode as never)
        })
    }
    secondList = firstNavi?.children || []
    return [menuCode, secondList]
}
