/*
 * @Author: duchengdong
 * @Date: 2021-11-05 14:36:40
 * @LastEditors: yangmiaomiao
 * @LastEditTime: 2022-03-09 11:48:19
 * @Description: 患者服务
 */
import http from 'utils/request'
import { PageInfo, ResObj } from 'types/config.type'

//1患者管理列表
export const getPatientList = (pageInfo: PageInfo, data: ResObj) => {
    return http({
        url: '/backstage/patient/page',
        method: 'post',
        data: { ...pageInfo, ...data },
    })
}

//2患者详情
export const getPatientInfo = (id: string) => {
    return http({
        url: `/backstage/patient/info/${id}`,
        method: 'get',
    })
}

//患者行为轨迹
export const getPatientAction = (data: ResObj) => {
    return http({
        url: `/backstage/patient/action`,
        method: 'post',
        data,
    })
}

//3编辑患者
export const savePatientInfo = (data: ResObj) => {
    return http({
        url: '/backstage/patient/save',
        method: 'post',
        data,
    })
}

//4关联医生
export const assDoctor = (data: ResObj) => {
    return http({
        url: '/backstage/doctor2patient/save',
        method: 'post',
        data,
    })
}

//5就诊医院下拉框数据
export const getHospitalOptions = (data?: ResObj) => {
    return http({
        url: '/backage/hospital/page',
        method: 'post',
        data,
    })
}

//6就诊医生下拉框数据
export const getDoctorOptions = (data?: ResObj) => {
    return http({
        url: '/backstage/doctor/doctorListByHospCode',
        method: 'post',
        data,
    })
}
