/*
 * @Author: yangmiaomiao
 * @Date: 2021-10-26 15:01:05
 * @LastEditors: duchengdong
 * @LastEditTime: 2022-02-24 17:55:08
 * @Description:
 */
import axios, { AxiosRequestConfig } from 'axios'
import { ResConfig, ResObj } from 'types/config.type'
import { Logout } from 'pages/Login/logout'
import { message } from 'antd'

const API_PATH = process.env.REACT_APP_API_PATH

axios.defaults.validateStatus = () => true
axios.defaults.timeout = 60000
axios.defaults.baseURL = API_PATH
axios.defaults.withCredentials = true
// 请求前
axios.interceptors.request.use(
    (config) => {
        // 设置请求头
        config.headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            ...(config.headers || {}),
        }

        return config
    },
    (error) => {},
)

// 请求后
axios.interceptors.response.use(
    (config) => {
        return config
    },
    (error) => {},
)
//eslint-disable-next-line
export default (config: AxiosRequestConfig) =>
    new Promise<ResConfig<ResObj>>((resolve) => {
        axios(config)
            .then((res: any) => {
                if (res?.data?.code === 104) {
                    message.error(res?.data?.msg || '请求失败')
                    window.location.href = `/login?redirectUrl=${encodeURIComponent(window.location.href)}`
                }
                if (res?.data?.code === 401) {
                    //登录状态已过期
                    message.error(res?.data?.msg || '请求失败')
                    if (!window.location.href.includes('localhost')) {
                        return Logout()
                    }
                } else {
                    resolve(res?.data || {})
                }
            })
            .catch(() => {})
    })
