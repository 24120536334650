/*
 * @Author: yangmiaomiao
 * @Date: 2021-10-26 15:06:29
 * @LastEditors: yangmiaomiao
 * @LastEditTime: 2021-11-18 11:09:46
 * @Description:
 */
interface DBModel {
    time?: number
    data: any
    name: string
}
//STORAGE_KEY
const spacename = process.env.REACT_APP_STORAGE_KEY
//用户信息保存时间
const ExpirationTime = (Number(process.env.I) || 2) * 1000 * 60 * 60
export const setStorage = (key: string, value: object | string | number, time: boolean = false) => {
    const DB: DBModel = {
        data: value,
        name: key,
    }
    if (!time) {
        DB.time = Date.now() + ExpirationTime
    }
    localStorage.setItem(`${spacename}_${key}`, JSON.stringify(DB))
}

// 获取值 判断是否过期
export const getStorage: (key: string) => any = (key: string) => {
    const data = localStorage.getItem(`${spacename}_${key}`)
    if (!data) return false
    const DB: DBModel = JSON.parse(data)
    if (DB.time && DB.time < Date.now()) return false
    return DB.data
}
